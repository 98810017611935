import React from 'react';

export const instagramIcon = () => {
  return (
    <svg fill="#FFFFFF" height="37px" width="37px" version="1.1" id="Shopicons"
      viewBox="0 0 48 48">
      <g>
        <path d="M36,4H12c-4.4,0-8,3.6-8,8v24c0,4.4,3.6,8,8,8h24c4.4,0,8-3.6,8-8V12C44,7.6,40.4,4,36,4z M24,34c-5.5,0-10-4.5-10-10
		s4.5-10,10-10s10,4.5,10,10S29.5,34,24,34z M35,15c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C37,14.1,36.1,15,35,15z"/>
      </g>
    </svg>
  );
};
